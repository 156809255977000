import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
    NAV_BAR_HEIGHT,
    BACKGROUND_COLOR,
    FONT_HEADER_COLOR,
    FONT_HEADER_SIZE,
    FONT_WEIGHT_BOLD,
} from "../../../assets/styles";
import Text from "../../../components/Text";
import Image from "../../../components/Image";
import { translate } from "../../../language/languageHandler";
import asdPic from "../../../assets/img/asd_painting.jpeg";
const APP_ICON_SIZE = 100;

class PolicyAG extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            addLanguagePackPopUp: false,
        };
    }

    render() {
        return (
            <div style={s.container}>
                {this.renderPolicy()}
            </div>
        );
    }

    renderHeader() {
        return (
            <Image
                style={s.imageHeader}
                src={asdPic}
                resizeMode={"cover"}
            >
                <div
                    style={s.headerContainer}>
                    <div
                        style={s.headerTextContainer}>
                        <Text
                            style={s.textHeader}
                            selectable={true}>
                            {translate("policy_header")}
                        </Text>
                    </div>
                </div>
            </Image>
        );
    }

    renderPolicy() {
        return (
            <div
                style={s.policyContainer}>
                <Text
                    style={s.textHeader}
                    selectable={true}>
                    {translate("policy_header")}
                </Text>
                <Text style={s.text}
                    selectable={true}>
                    {translate("policy_header_text")}
                </Text>
                <Text style={s.textSubHeader}
                    selectable={true}>
                    {translate("policy_information_collection_and_use_header")}
                </Text>
                <Text style={s.text}
                    selectable={true}>
                    {translate("policy_information_collection_and_use_text")}
                </Text>
                <Text style={s.textSubHeader}
                    selectable={true}>
                    {translate("policy_log_data_header")}
                </Text>
                <Text style={s.text}
                    selectable={true}>
                    {translate("policy_log_data_text")}
                </Text>
                <Text style={s.textSubHeader}
                    selectable={true}>
                    {translate("policy_cookies_header")}
                </Text>
                <Text style={s.text}
                    selectable={true}>
                    {translate("policy_cookies_text")}
                </Text>
                <Text style={s.textSubHeader}
                    selectable={true}>
                    {translate("policy_service_providers_header")}
                </Text>
                <Text style={s.text}
                    selectable={true}>
                    {translate("policy_service_providers_text")}
                </Text>
                <Text style={s.textSubHeader}
                    selectable={true}>
                    {translate("policy_security_header")}
                </Text>
                <Text style={s.text}
                    selectable={true}>
                    {translate("policy_security_text")}
                </Text>
                <Text style={s.textSubHeader}
                    selectable={true}>
                    {translate("policy_links_to_other_cites_header")}
                </Text>
                <Text style={s.text}
                    selectable={true}>
                    {translate("policy_links_to_other_cites_text")}
                </Text>
                <Text style={s.textSubHeader}
                    selectable={true}>
                    {translate("policy_childrens_privacy_header")}
                </Text>
                <Text style={s.text}
                    selectable={true}>
                    {translate("policy_childrens_privacy_text")}
                </Text>
                <Text style={s.textSubHeader}
                    selectable={true}>
                    {translate("policy_changes_to_this_privacy_policy_header")}
                </Text>
                <Text style={s.text}
                    selectable={true}>
                    {translate("policy_changes_to_this_privacy_policy_text")}
                </Text>
                <Text style={s.textSubHeader}
                    selectable={true}>
                    {translate("policy_contact_us_header")}
                </Text>
                <Text style={s.text}
                    selectable={true}>
                    {translate("policy_contact_us_text")}
                </Text>
            </div>
        );
    }
}

export default withRouter(PolicyAG);

const s = {
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: BACKGROUND_COLOR,
        minHeight: "100%",
        width: "100%",
        overflow: "hidden",
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        width: "100%",
        background: "linear-gradient(to bottom right, #FFFFFF, #B4B4B4, #B4B4B4, #B4B4B4)",
        backgroundBlendMode: "screen",
        opacity: "95%",
    },
    headerTextContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        width: "800px",
        maxWidth: "90vw",
    },
    policyContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "center",
        width: "800px",
        maxWidth: "90vw",
        marginTop: "50px",
        marginBottom: "50px",
    },
    textHeader: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        //justifyContent: "center",
        //color: FONT_HEADER_COLOR,
        fontSize: FONT_HEADER_SIZE,
        fontWeight: FONT_WEIGHT_BOLD,
        marginBottom: "50px",
    },
    textSubHeader: {
        display: "flex",
        flex: 1,
        fontSize: 34,
        fontWeight: FONT_WEIGHT_BOLD,
        padding: "5px",
        marginBottom: "50px",
        marginTop: "50px",
        alignItems: "left",
    },
    text: {
        display: "flex",
        flex: 1,
        fontSize: "calc(18px + .3vw)",
        padding: "5px",
    },
    imageHeader: {
        width: "100%",
        height: "calc(92vh - " + (NAV_BAR_HEIGHT) + "px)",
    },
};