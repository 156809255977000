import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
    NAV_BAR_HEIGHT,
    NAV_BAR_BACKGROUND_COLOR,
    MARGIN_LEFT,
    MARGIN_RIGHT,
    MARGIN_TOP,
    ICON_BULLET_POINT_COLOR,
    SELECTED_COLOR,
    ICON_BULLET_POINT_SIZE
} from "../../assets/styles";
import NavbarCore from "./NavbarCore";
import Image from "../Image";
import IconButton from "../IconButton";
import TextButton from "../TextButton";
import OpacityOnClick from "../OpacityOnClick";
import { translate } from "../../language/languageHandler";
import { isEmptyEvaluator } from "../../logic/Utilities";
import AsdLogo from "../AsdLogo";
import Drawer from "../Drawer";

class NavbarDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerOpen: false,
        }
    }

    changeScreen(path) {
        this.props.history.push("/" + path);
    }

    scrollToTop() {
        window.scrollTo(0, 0)
    };

    scrollToElement(elem) {
        setTimeout(() => {
            const elemNode = document.getElementById(elem)
            elemNode.scrollIntoView({ behavior: "auto" });
        }, 0);
    };

    closeDrawer() {
        this.setState({ drawerOpen: false });
    }

    drawerChoice(path, scrollTo) {
        this.closeDrawer();
        this.changeScreen(path);
        if (scrollTo != "") {
            this.scrollToElement(scrollTo);
        }
        else {
            this.scrollToTop();
        }
    }

    render() {
        let drawerOpen = this.state.drawerOpen;
        return (
            <div style={s.container}>
                {this.renderLeftSide()}
                {this.renderRightSide()}
                {this.renderDrawer(drawerOpen)}
            </div>
        );
    }

    renderLeftSide() {
        return (
            <div
                style={s.leftSideContainer}>
                {this.renderLogo()}
            </div>
        );
    }

    renderLogo() {
        return (
            <OpacityOnClick
                style={s.logoContainer}
                onClick={() => this.drawerChoice("", "")}>
                <AsdLogo style={s.logo} />
            </OpacityOnClick>
        );
    }

    renderRightSide() {
        return (
            <div style={s.rightSideContainer}>
                <IconButton
                    icon={"menu"}
                    size={ICON_BULLET_POINT_SIZE}
                    color={ICON_BULLET_POINT_COLOR}
                    animationColor={SELECTED_COLOR}
                    onClick={() => this.setState({ drawerOpen: true })}
                />
            </div>
        );
    }

    renderDrawer(drawerOpen) {
        return (
            <Drawer
                anchor={"right"}
                open={drawerOpen}
                onClose={() => this.setState({ drawerOpen: false })}
            >
                <div style={s.menuContainer}>
                    <TextButton
                        style={s.menuText}
                        label={translate("home_header")}
                        onClick={() => this.drawerChoice("", "")}
                    />
                    <TextButton
                        style={s.menuText}
                        label={translate("project_header")}
                        onClick={() => this.drawerChoice("projects", "")}
                    />
                    <TextButton
                        style={s.menuText}
                        label={translate("audio_guide_header")}
                        onClick={() => this.drawerChoice("projects", "projectAudioGuide")}
                    />
                    <TextButton
                        style={s.menuText}
                        label={translate("about_header")}
                        onClick={() => this.drawerChoice("about", "")}
                    />
                    <TextButton
                        style={s.menuText}
                        label={translate("contact_header")}
                        onClick={() => this.drawerChoice("", "bottomBar")}
                    />
                    <TextButton
                        style={s.menuText}
                        label={translate("policy_header")}
                        onClick={() => this.drawerChoice("privacy-policy-audio-guide", "")}
                    />
                </div>
            </Drawer>
        );
    }
}

export default withRouter(NavbarDesktop);

const s = {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: NAV_BAR_BACKGROUND_COLOR,
        paddingLeft: MARGIN_LEFT,
        paddingRight: MARGIN_RIGHT,
        position: "fixed", top: 0, left: 0, right: 0,
        boxShadow: "rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px",
    },
    leftSideContainer: {
        display: "flex",
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    logo: {
        width: 120,
        height: NAV_BAR_HEIGHT,
    },
    navbarContainer: {
        width: 250,
    },
    rightSideContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    menuContainer: {
        width: "300px",
        padding: MARGIN_TOP,
    },
    menuText: {
        display: "flex",
        flex: 1,
        fontSize: "calc(18px + .3vw)",
        padding: MARGIN_TOP,
    },
};