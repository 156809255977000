export function formatDate(unformattedDate) {
    let date = new Date(unformattedDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let min = date.getMinutes();
    if (("" + month).length < 2) {
        month = "0" + month;
    }
    if (("" + day).length < 2) {
        day = "0" + day;
    }
    if (("" + hour).length < 2) {
        hour = "0" + hour;
    }
    if (("" + min).length < 2) {
        min = "0" + min;
    }
    let formatDateTime = "" + year + "-" + month + "-" + day + " " + hour + ":" + min;
    return formatDateTime;
}

export function formatTime(time) {
    let h = 60 * 60 * 1000;
    let m = 60 * 1000;
    let hours = Math.floor(time / h);
    let minutes = Math.floor((time % h) / m);
    let seconds = Math.floor(((time % h) % m) / 1000);
    if (hours < 1) {
        hours = "";
    }
    else if (hours < 10) {
        hours = "0" + hours + ":";
    }
    else {
        hours = hours + ":"
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    let formatedTime = hours + minutes + ":" + seconds;
    return formatedTime;
}

export function formatTimeRoudnedMin(milliSec) {
    let seconds = milliSec / 1000;
    let mins = seconds / 60;
    let roudnedMins = Math.round(mins);
    return roudnedMins;
}

export function getCurrentTime() {
    return Date.parse(new Date());
}

export function openLink(url, openInNewTab) {
    if (openInNewTab) {
        window.open(url, "_blank");
    }
    else {
        window.location.href = url;
    }
}

export function isEmptyEvaluator(item) {
    if (item === "" || item === null || typeof item === "undefined") {
        return true;
    }
    else {
        return false;
    }
}

export function splitFullName(fullName) {
    let nameArray = fullName.split(" ");
    if (nameArray.length < 2) {
        return {
            firstName: nameArray[0],
            lastName: ""
        };
    }
    else {
        return {
            firstName: nameArray[0],
            lastName: nameArray[1]
        };
    }
}

export function toTitleCase(string) {
    if (isEmptyEvaluator(string)) {
        return "";
    }
    let stringList = string.split(" ");
    let stringListTitleCase = [];
    for (let i = 0; i < stringList.length; i++) {
        let titleCaseString = stringList[i].charAt(0).toUpperCase() + stringList[i].substr(1).toLowerCase();
        stringListTitleCase.push(titleCaseString);
    }
    return stringListTitleCase.join(" ");
}

export function createHashKey() {
    return Math.random().toString(36).substr(2, 11).toUpperCase();
}

export function regExpMatch(regularExpression, string) {
    return RegExp(regularExpression).test(string);
}

export function sortList(list, key) {
    list.sort((a, b) => {
        if (a[key] > b[key]) {
            return 1;
        }
        if (a[key] < b[key]) {
            return -1;
        }
        return 0;
    });
    return list;
}

export function scrollToTop() {
    window.scrollTo(0, 0);
}