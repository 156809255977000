import React, { Component } from "react";
import {
    IMAGE_PLACE_HOLDER_COLOR,
} from "../assets/styles.js";
import { isEmptyEvaluator } from "../logic/Utilities";

class Image extends Component {

    render() {
        let style = this.props.style;
        let src = this.props.src;
        let resizeMode = this.props.resizeMode;
        let fade = this.props.fade;
        let children = this.props.children;
        let image = "url(" + src + ")";
        if (fade) {
            image = "linear-gradient(0deg, " + fade + "FF 0%, " + fade + "00 30%), url(" + src + ")";
        }
        if (isEmptyEvaluator(src)) {
            return (<div />);
        }
        if (isEmptyEvaluator(resizeMode)) {
            resizeMode = "cover";
        }
        const s = {
            container: {
                backgroundImage: image,
                backgroundSize: resizeMode,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundColor: IMAGE_PLACE_HOLDER_COLOR,
            },
        }
        return (
            <div style={{ ...s.container, ...style }}>
                {children}
            </div>
        );
    }
}

export default Image;