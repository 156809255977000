import React, { Component } from "react";
import {
    FONT_BODY_COLOR,
    FONT_BODY_SIZE,
    FONT_WEIGHT_NORMAL
} from "../assets/styles";

class Text extends Component {

    render() {
        let style = this.props.style;
        let selectable = this.props.selectable;
        let children = this.props.children;
        if (typeof children === "string" || typeof children === "number") {
            let notSelectable = s.notSelectable;
            if (selectable) {
                notSelectable = null;
            }
            return (
                <div style={{ ...s.text, ...notSelectable, ...style }}>
                    {children}
                </div>
            );
        }
        else {
            return (
                <div style={{ ...s.text, ...{ color: "red" } }}>
                    {"Error: Text is the only thing this component can return"}
                </div>
            );
        }
    }
}

export default Text;

const s = {
    text: {
        color: FONT_BODY_COLOR,
        fontSize: FONT_BODY_SIZE,
        fontWeight: FONT_WEIGHT_NORMAL,
        whiteSpace: "pre-wrap",
        //fontFamily: "Bourgeois" 
    },
    notSelectable: {
        "-webkit-user-select": "none",
        "-ms-user-select": "none",
        userSelect: "none",
    },
};