import React, { Component } from "react";
import { isEmptyEvaluator } from "../logic/Utilities";
import opacity from "../assets/cssStyles/onClickOpacity.module.css";


class OpacityOnClick extends Component {

    emptyFunction() {
        return;
    }

    render() {
        let id = this.props.id;
        let style = this.props.style;
        let onClickFunc = this.props.onClick;
        let children = this.props.children;
        
        if (isEmptyEvaluator(onClickFunc)) {
            onClickFunc = () => this.emptyFunction();
        }
        return (
            <div 
                id={id}
                style={{...s.container, ...style}}
                className={`${opacity.desktop} ${opacity.fadeback}`}
                onClick={() => onClickFunc()}
            >
                {children}
            </div>
        );
    }
}

export default OpacityOnClick;

const s = {
    container: {
        cursor: "pointer",
        "-webkit-tap-highlight-color": "transparent",
        "-webkit-user-select": "none",
        "-ms-user-select": "none",
        userSelect: "none",
    },
};