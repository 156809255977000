import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
    FONT_WEIGHT_BOLD,
} from "../assets/styles";
import Text from "./Text";
import { translate } from "../language/languageHandler";
import OpacityOnClick from "./OpacityOnClick";
import AsdLogo from "./AsdLogo";

class Bottombar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    };

    changeScreen(path) {
        this.props.history.push("/" + path);
        this.scrollToTop();
    }

    render() {
        return (
            <div id={"bottomBar"} style={s.container}>
                {this.renderRightSide()}
            </div>
        );
    }

    renderRightSide() {
        return (
            <div style={s.rightSideContainer}>
                {this.renderLogo()}
                <Text
                    style={s.text}
                    selectable={true}>
                    {translate("contact_name")}
                </Text>
                <Text
                    style={s.text}
                    selectable={true}>
                    {translate("contact_email")}
                </Text>
                <Text
                    style={s.text}
                    selectable={true}>
                    {translate("contact_phone_number")}
                </Text>
                <Text
                    style={s.text}
                    selectable={true}>
                    {translate("contact_org_nr")}
                </Text>
            </div>
        );
    }

    renderLogo() {
        return (
            <OpacityOnClick style={s.logoContainer} onClick={() => this.changeScreen("")}>
                <AsdLogo style={s.logo} />
            </OpacityOnClick>
        );
    }

}

export default withRouter(Bottombar);

const s = {
    container: {
        display: "flex",
        flex: 1,
        //backgroundColor: "#AAAAAA",
        height: 350,
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
    },
    logo: {
        width: "80vw",
        maxWidth: 500,
    },
    rightSideContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "flex-end",
        borderTop: '1px solid  rgb(0, 0, 0, 0.3)',
    },
    text: {
        fontSize: "calc(18px + .3vw)",
        fontWeight: FONT_WEIGHT_BOLD,
        marginRight: "calc(18px + 5vw)",
    },
};
