import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
    NAV_BAR_HEIGHT,
    BACKGROUND_COLOR,
    FONT_HEADER_COLOR,
    FONT_HEADER_SIZE,
    FONT_WEIGHT_BOLD,
    MARGIN_TOP,
    CARD_BOX_SHADOW,
    MAX_DESKTOP_WIDTH,
} from "../../../assets/styles";
import Text from "../../../components/Text";
import Image from "../../../components/Image";
import AppIcon from "../../../components/AppIcon";
import OpacityOnClick from "../../../components/OpacityOnClick";
import { translate } from "../../../language/languageHandler";
import psstLogo from "../../../assets/img/psst_icon.png";
import ffwIcon from "../../../assets/img/ffw_icon.png";
import avanzaIcon from "../../../assets/img/avanza_icon.png";
import nordiskaMuseetIcon from "../../../assets/img/nordiska_museet_icon.png";
import jamtliIcon from "../../../assets/img/jamtli_icon.png";
import asdPic from "../../../assets/img/asd_painting.jpeg";
const APP_ICON_SIZE = 100;

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            addLanguagePackPopUp: false,
        };
    }

    changeScreen(path) {
        this.props.history.push("/" + path);
    }

    scrollToTop() {
        window.scrollTo(0, 0)
    };

    scrollToElement(elem) {
        setTimeout(() => {
            const elemNode = document.getElementById(elem)
            elemNode.scrollIntoView({ behavior: "auto" });
        }, 0);
    };

    menuChoice(path, scrollTo) {
        this.changeScreen(path);
        if (scrollTo != "") {
            this.scrollToElement(scrollTo);
        }
        else {
            this.scrollToTop();
        }
    }

    scrollToBottom() {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    render() {
        return (
            <div style={s.container}>
                {this.renderHeader()}
                {this.renderAboutCard()}
                {this.renderMenuCards()}
                {this.renderProjectsCard()}
            </div>
        );
    }

    renderHeader() {
        return (
            <Image
                style={s.imageHeader}
                src={asdPic}
                resizeMode={"cover"}
            >
                <div
                    style={s.headerContainer}>
                    <div
                        style={s.headerTextContainer}>
                        <Text
                            style={s.textHeader}
                            selectable={true}>
                            {translate("home_welcome")}
                        </Text>
                    </div>
                </div>
            </Image>
        );
    }

    renderAboutCard() {
        return (
            <div
                style={s.aboutContainer}>
                <Text style={s.text}
                    selectable={true}>
                    {translate("home_about")}
                </Text>
            </div>
        );
    }

    renderMenuCards() {
        return (
            <Image
                src={asdPic}
                resizeMode={"cover"}
            >
                <div
                    style={s.menuContainer}>
                    <OpacityOnClick
                        style={{
                            ...s.menuTextContainer,
                            backgroundColor: "rgb(237, 144, 228, 0.85)"
                        }}
                        onClick={() => this.menuChoice("projects", "")}>
                        <Text
                            style={s.textMenu}
                            selectable={true}>
                            {translate("project_header")}
                        </Text>
                    </OpacityOnClick>
                    <OpacityOnClick
                        style={{
                            ...s.menuTextContainer,
                            backgroundColor: "rgb(247, 94, 75, 0.85)"
                        }}
                        onClick={() => this.menuChoice("about", "")}>
                        <Text
                            style={s.textMenu}
                            selectable={true}>
                            {translate("about_header")}
                        </Text>
                    </OpacityOnClick>
                    <OpacityOnClick
                        style={{
                            ...s.menuTextContainer,
                            backgroundColor: "rgb(2, 172, 242, 0.85)"
                        }}
                        onClick={() => this.menuChoice("projects", "projectAudioGuide")}>
                        <Text
                            style={s.textMenu}
                            selectable={true}>
                            {translate("audio_guide_header")}
                        </Text>
                    </OpacityOnClick>
                    <OpacityOnClick
                        style={{
                            ...s.menuTextContainer,
                            backgroundColor: "rgb(180, 180, 180, 0.85)"
                        }}
                        onClick={() => this.scrollToBottom()}>
                        <Text
                            style={s.textMenu}
                            selectable={true}>
                            {translate("contact_header")}
                        </Text>
                    </OpacityOnClick>
                </div>
            </Image>
        );
    }

    renderProjectsCard() {
        return (
            <div style={s.projectsContainer}>
                <Text style={s.projectHeader} selectable={true}>
                    {translate("home_projects")}
                </Text>
                <div style={s.scrollContainer}>
                    <AppIcon
                        style={s.appIcon}
                        icon={psstLogo}
                        size={APP_ICON_SIZE}
                        onClick={() => this.menuChoice("projects", "projectPsst")}
                    />
                    <AppIcon
                        style={s.appIcon}
                        icon={ffwIcon}
                        size={APP_ICON_SIZE}
                        onClick={() => this.menuChoice("projects", "projectFfw")}
                    />
                    <AppIcon
                        style={s.appIcon}
                        icon={avanzaIcon}
                        size={APP_ICON_SIZE}
                        onClick={() => this.menuChoice("projects", "projectAvanza")}
                    />
                    <AppIcon
                        style={s.appIcon}
                        icon={nordiskaMuseetIcon}
                        size={APP_ICON_SIZE}
                        onClick={() => this.menuChoice("projects", "projectAudioGuide")}
                    />
                    <AppIcon
                        style={s.appIcon}
                        icon={jamtliIcon}
                        size={APP_ICON_SIZE}
                        onClick={() => this.menuChoice("projects", "projectAudioGuide")}
                    />
                </div>
            </div>
        );
    }


}

export default withRouter(Home);

const s = {
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: BACKGROUND_COLOR,
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        background: "linear-gradient(to bottom right, #FFFFFF, #ED90E4, #F75E4B, #02ACF2)",
        backgroundBlendMode: "screen",
        opacity: "95%",
    },
    headerTextContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "90vw",
        maxWidth: MAX_DESKTOP_WIDTH,
    },
    aboutContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "90vw",
        maxWidth: MAX_DESKTOP_WIDTH,
        marginTop: "50px",
        marginBottom: "50px",
    },
    menuContainer: {
        width: "90vw",
        maxWidth: MAX_DESKTOP_WIDTH,
    },
    menuTextContainer: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: 400,
        maxWidth: "90vw",
        height: 230,
        maxHeight: "52vw",
        border: '5px solid  #FFFFFF',
        float: "left",
        boxSizing: "border-box",
    },
    textHeader: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        color: FONT_HEADER_COLOR,
        fontSize: FONT_HEADER_SIZE,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    textMenu: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        color: FONT_HEADER_COLOR,
        fontSize: 34,
        fontWeight: FONT_WEIGHT_BOLD,
    },

    text: {
        display: "flex",
        flex: 1,
        fontSize: "calc(18px + .3vw)",
        padding: "5px",
    },
    projectHeader: {
        fontSize: 34,
        fontWeight: FONT_WEIGHT_BOLD,
        padding: 30,
        marginTop: 50,
        marginBottom: 50,
    },
    projectsContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: MAX_DESKTOP_WIDTH,
    },
    scrollContainer: {
        display: "flex",
        width: "calc(100vw - " + 30 + "px)",
        maxWidth: MAX_DESKTOP_WIDTH,
        overflowX: "auto",
        paddingLeft: 30,
        paddingBottom: 30,
    },
    appIcon: {
        minWidth: 100,
        marginRight: 30,
        boxShadow: CARD_BOX_SHADOW,
    },
    imageHeader: {
        width: "100%",
        height: "calc(92vh - " + (NAV_BAR_HEIGHT) + "px)",
    },
};