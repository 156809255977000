import React from "react";
import {
    MoreVert,
    Reorder,
    Add,
    AccessTime,
    ArrowDropDown,
    Home,
    Restaurant,
    Person,
    Loyalty,
    AccountCircle,
    People,
    Info,
    Input,
    NavigateNext,
    Done,
    Share,
    Block,
    Backspace,
    Event,
    Room,
    Chat,
    Payment,
    CardMembership,
    RadioButtonChecked,
    RadioButtonUnchecked,
    Devices,
    Cancel,
    ShoppingCart,
    Favorite,
    Email,
    ExpandMore,
    Search,
    SmsFailed,
    PlaylistAdd,
    Phone,
    Help,
    Fingerprint,
    Business,
    Apartment,
    Lock,
    Visibility,
    VisibilityOff,
    ErrorOutline,
    CheckCircleOutline,
    InfoOutlined,
    ReportProblemOutlined,
    Grade,
    LocalOffer,
    ChevronLeft,
    ChevronRight,
    Language,
    Public,
    SwapHoriz,
    SettingsBackupRestore,
    Collections,
    Audiotrack,
    Delete,
    Edit,
    CloudUpload,
    Image,
    BrokenImage,
    PlayArrow,
    SkipNext,
    SkipPrevious,
    Replay10,
    Forward10,
    Pause,
    Stop,
    GetApp,
    OfflinePin,
    Code,
    Menu,
} from "@material-ui/icons";

export const iconLibraryDefault = {
    accessTime: (style) => <AccessTime style={style} />,
    accountCircle: (style) => <AccountCircle style={style} />,
    add: (style) => <Add style={style} />,
    apartment: (style) => <Apartment style={style} />,
    arrowDropDown: (style) => <ArrowDropDown style={style} />,
    audiotrack: (style) => <Audiotrack style={style} />,
    backspace: (style) => <Backspace style={style} />,
    block: (style) => <Block style={style} />,
    brokenImage: (style) => <BrokenImage style={style} />,
    business: (style) => <Business style={style} />,
    calendar: (style) => <Event style={style} />,
    cancel: (style) => <Cancel style={style} />,
    cardMembership: (style) => <CardMembership style={style} />,
    code: (style) => <Code style={style} />,
    collections: (style) => <Collections style={style} />,
    chat: (style) => <Chat style={style} />,
    checkCircleOutline: (style) => <CheckCircleOutline style={style} />,
    chevronLeft: (style) => <ChevronLeft style={style} />,
    chevronRight: (style) => <ChevronRight style={style} />,
    cloudUpload: (style) => <CloudUpload style={style} />,
    delete: (style) => <Delete style={style} />,
    devices: (style) => <Devices style={style} />,
    done: (style) => <Done style={style} />,
    download: (style) => <GetApp style={style} />,
    edit: (style) => <Edit style={style} />,
    errorOutline: (style) => <ErrorOutline style={style} />,
    expandMore: (style) => <ExpandMore style={style} />,
    facebookMessenger: (style) => messenger(style),
    favorite: (style) => <Favorite style={style} />,
    feedback: (style) => <SmsFailed style={style} />,
    fingerprint: (style) => <Fingerprint style={style} />,
    forward10: (style) => <Forward10 style={style} />,
    grade: (style) => <Grade style={style} />,
    help: (style) => <Help style={style} />,
    home: (style) => <Home style={style} />,
    image: (style) => <Image style={style} />,
    info: (style) => <Info style={style} />,
    infoOutlined: (style) => <InfoOutlined style={style} />,
    input: (style) => <Input style={style} />,
    language: (style) => <Language style={style} />,
    lock: (style) => <Lock style={style} />,
    localOffer: (style) => <LocalOffer style={style} />,
    loyalty: (style) => <Loyalty style={style} />,
    mail: (style) => <Email style={style} />,
    menu: (style) => <Menu style={style} />,
    moreVert: (style) => <MoreVert style={style} />,
    navigateNext: (style) => <NavigateNext style={style} />,
    none: (style) => <div style={{ height: style.fontSize, width: style.fontSize }} />,
    offlinePin: (style) => <OfflinePin style={style} />,
    pay: (style) => <Payment style={style} />,
    pause: (style) => <Pause style={style} />,
    people: (style) => <People style={style} />,
    person: (style) => <Person style={style} />,
    phone: (style) => <Phone style={style} />,
    playArrow: (style) => <PlayArrow style={style} />,
    playlistAdd: (style) => <PlaylistAdd style={style} />,
    public: (style) => <Public style={style} />,
    radioButtonChecked: (style) => <RadioButtonChecked style={style} />,
    radioButtonUnchecked: (style) => <RadioButtonUnchecked style={style} />,
    replay10: (style) => <Replay10 style={style} />,
    reportProblemOutlined: (style) => <ReportProblemOutlined style={style} />,
    reorder: (style) => <Reorder style={style} />,
    restaurant: (style) => <Restaurant style={style} />,
    room: (style) => <Room style={style} />,
    search: (style) => <Search style={style} />,
    settingsBackupRestore: (style) => <SettingsBackupRestore style={style} />,
    share: (style) => <Share style={style} />,
    shoppingCart: (style) => <ShoppingCart style={style} />,
    skipNext: (style) => <SkipNext style={style} />,
    skipPrevious: (style) => <SkipPrevious style={style} />,
    stop: (style) => <Stop style={style} />,
    swapHoriz: (style) => <SwapHoriz style={style} />,
    visibility: (style) => <Visibility style={style} />,
    visibilityOff: (style) => <VisibilityOff style={style} />,
}

function messenger(style) {
    let { fontSize: size, color: color } = style;
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 50 59"
            fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                fill={color}
                fillRule="evenodd"
                d="M25 8.6C11.1965 8.6 0 18.9018 0 31.6679C0 38.4282 3.15048 44.7568 8.69565 49.1574V58.6L18.0452 53.7718C20.3252 54.4131 22.6095 54.6268 25 54.6268C38.8035 54.6268 50 44.3293 50 31.5589C50 18.9018 38.8035 8.6 25 8.6ZM27.5008 39.2873L21.1957 32.6319L9.45567 39.1784L22.4992 25.5531L28.9147 31.8816L40.3278 25.5531L27.5008 39.2873Z"
                clipRule="evenodd"
            />
        </svg>
    );
}