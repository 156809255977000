import React, { Component } from "react";
import OpacityOnClick from "./OpacityOnClick";
import Text from "./Text.js";

class TextButton extends Component {

    render() {
        let onClick = this.props.onClick
        let label = this.props.label;
        let fontSize = this.props.fontSize;
        let color = this.props.color;
        let fontWeight = this.props.fontWeight;
        let textTransform = this.props.textTransform;
        let style = this.props.style;
        const style2 = {
            fontSize: fontSize,
            color: color,
            fontWeight: fontWeight,
            textTransform: textTransform,
        };
        return (
            <OpacityOnClick style={style} onClick={() => onClick()}>
                <Text style={style2}>
                    {label}
                </Text>
            </OpacityOnClick>
        );
    }
}

export default TextButton;