//Here we define the language list, what a language code corresponds to.
export const languageList = [
    {code: "en", lang: "English"},
    {code: "en-GB", lang: "English"},
    {code: "en-US", lang: "English"},
    {code: "sv", lang: "Swedish"},
    {code: "ab", lang: "Abkhazian"},
    {code: "aa", lang: "Afar"},
    {code: "af", lang: "Afrikaans"},
    {code: "ak", lang: "Akan"},
    {code: "sq", lang: "Albanian"},
    {code: "am", lang: "Amharic"},
    {code: "ar", lang: "Arabic"},
    {code: "an", lang: "Aragonese"},
    {code: "hy", lang: "Armenian"},
    {code: "as", lang: "Assamese"},
    {code: "av", lang: "Avaric"},
    {code: "ae", lang: "Avestan"},
    {code: "ay", lang: "Aymara"},
    {code: "az", lang: "Azerbaijani"},
    {code: "bm", lang: "Bambara"},
    {code: "ba", lang: "Bashkir"},
    {code: "eu", lang: "Basque"},
    {code: "be", lang: "Belarusian"},
    {code: "bn", lang: "Bengali (Bangla)"},
    {code: "bh", lang: "Bihari"},
    {code: "bi", lang: "Bislama"},
    {code: "bs", lang: "Bosnian"},
    {code: "br", lang: "Breton"},
    {code: "bg", lang: "Bulgarian"},
    {code: "my", lang: "Burmese"},
    {code: "ca", lang: "Catalan"},
    {code: "ch", lang: "Chamorro"},
    {code: "ce", lang: "Chechen"},
    {code: "ny", lang: "Chichewa, Chewa, Nyanja"},
    {code: "zh", lang: "Chinese"},
    {code: "zh-Hans", lang: "Chinese (Simplified)"},
    {code: "zh-Hant", lang: "Chinese (Traditional)"},
    {code: "cv", lang: "Chuvash"},
    {code: "kw", lang: "Cornish"},
    {code: "co", lang: "Corsican"},
    {code: "cr", lang: "Cree"},
    {code: "hr", lang: "Croatian"},
    {code: "cs", lang: "Czech"},
    {code: "da", lang: "Danish"},
    {code: "dv", lang: "Divehi, Dhivehi, Maldivian"},
    {code: "nl", lang: "Dutch"},
    {code: "dz", lang: "Dzongkha"},
    {code: "eo", lang: "Esperanto"},
    {code: "et", lang: "Estonian"},
    {code: "ee", lang: "Ewe"},
    {code: "fo", lang: "Faroese"},
    {code: "fj", lang: "Fijian"},
    {code: "fi", lang: "Finnish"},
    {code: "fr", lang: "French"},
    {code: "ff", lang: "Fula, Fulah, Pulaar, Pular"},
    {code: "gl", lang: "Galician"},
    {code: "gd", lang: "Gaelic (Scottish)"},
    {code: "gv", lang: "Gaelic (Manx)"},
    {code: "ka", lang: "Georgian"},
    {code: "de", lang: "German"},
    {code: "el", lang: "Greek"},
    {code: "kl", lang: "Greenlandic"},
    {code: "gn", lang: "Guarani"},
    {code: "gu", lang: "Gujarati"},
    {code: "ht", lang: "Haitian Creole"},
    {code: "ha", lang: "Hausa"},
    {code: "he", lang: "Hebrew"},
    {code: "hz", lang: "Herero"},
    {code: "hi", lang: "Hindi"},
    {code: "ho", lang: "Hiri"},
    {code: "hu", lang: "Hungarian"},
    {code: "is", lang: "Icelandic"},
    {code: "io", lang: "Ido"},
    {code: "ig", lang: "Igbo"},
    {code: "id,in", lang: "Indonesian"},
    {code: "ia", lang: "Interlingua"},
    {code: "ie", lang: "Interlingue"},
    {code: "iu", lang: "Inuktitut"},
    {code: "ik", lang: "Inupiak"},
    {code: "ga", lang: "Irish"},
    {code: "it", lang: "Italian"},
    {code: "ja", lang: "Japanese"},
    {code: "jv", lang: "Javanese"},
    {code: "kl", lang: "Kalaallisut, Greenlandic"},
    {code: "kn", lang: "Kannada"},
    {code: "kr", lang: "Kanuri"},
    {code: "ks", lang: "Kashmiri"},
    {code: "kk", lang: "Kazakh"},
    {code: "km", lang: "Khmer"},
    {code: "ki", lang: "Kikuyu 	"},
    {code: "rw", lang: "Kinyarwanda (Rwanda)"},
    {code: "rn", lang: "Kirundi"},
    {code: "ky", lang: "Kyrgyz"},
    {code: "kv", lang: "Komi"},
    {code: "kg", lang: "Kongo"},
    {code: "ko", lang: "Korean"},
    {code: "ku", lang: "Kurdish"},
    {code: "kj", lang: "Kwanyama"},
    {code: "lo", lang: "Lao"},
    {code: "la", lang: "Latin"},
    {code: "lv", lang: "Latvian (Lettish)"},
    {code: "li", lang: "Limburgish (Limburger)"},
    {code: "ln", lang: "Lingala"},
    {code: "lt", lang: "Lithuanian"},
    {code: "lu", lang: "Luga-Katanga"},
    {code: "lg", lang: "Luganda, Ganda"},
    {code: "lb", lang: "Luxembourgish"},
    {code: "gv", lang: "Manx"},
    {code: "mk", lang: "Macedonian"},
    {code: "mg", lang: "Malagasy"},
    {code: "ms", lang: "Malay"},
    {code: "ml", lang: "Malayalam"},
    {code: "mt", lang: "Maltese"},
    {code: "mi", lang: "Maori"},
    {code: "mr", lang: "Marathi"},
    {code: "mh", lang: "Marshallese"},
    {code: "mo", lang: "Moldavian"},
    {code: "mn", lang: "Mongolian"},
    {code: "na", lang: "Nauru"},
    {code: "nv", lang: "Navajo"},
    {code: "ng", lang: "Ndonga"},
    {code: "nd", lang: "Northern Ndebele"},
    {code: "ne", lang: "Nepali"},
    {code: "no", lang: "Norwegian"},
    {code: "nb", lang: "Norwegian bokmål"},
    {code: "nn", lang: "Norwegian nynorsk"},
    {code: "ii", lang: "Nuosu"},
    {code: "oc", lang: "Occitan"},
    {code: "oj", lang: "Ojibwe"},
    {code: "cu", lang: "Old Church Slavonic, Old Bulgarian"},
    {code: "or", lang: "Oriya"},
    {code: "om", lang: "Oromo (Afaan Oromo)"},
    {code: "os", lang: "Ossetian"},
    {code: "pi", lang: "Pāli"},
    {code: "ps", lang: "Pashto, Pushto"},
    {code: "fa", lang: "Persian (Farsi)"},
    {code: "pl", lang: "Polish"},
    {code: "pt", lang: "Portuguese"},
    {code: "pa", lang: "Punjabi (Eastern)"},
    {code: "qu", lang: "Quechua"},
    {code: "rm", lang: "Romansh"},
    {code: "ro", lang: "Romanian"},
    {code: "ru", lang: "Russian"},
    {code: "se", lang: "Sami"},
    {code: "sm", lang: "Samoan"},
    {code: "sg", lang: "Sango"},
    {code: "sa", lang: "Sanskrit"},
    {code: "sr", lang: "Serbian"},
    {code: "sh", lang: "Serbo-Croatian"},
    {code: "st", lang: "Sesotho"},
    {code: "tn", lang: "Setswana"},
    {code: "sn", lang: "Shona"},
    {code: "ii", lang: "Sichuan Yi"},
    {code: "sd", lang: "Sindhi"},
    {code: "si", lang: "Sinhalese"},
    {code: "ss", lang: "Siswati"},
    {code: "sk", lang: "Slovak"},
    {code: "sl", lang: "Slovenian"},
    {code: "so", lang: "Somali"},
    {code: "nr", lang: "Southern Ndebele"},
    {code: "es", lang: "Spanish"},
    {code: "su", lang: "Sundanese"},
    {code: "sw", lang: "Swahili (Kiswahili)"},
    {code: "ss", lang: "Swati"},
    {code: "tl", lang: "Tagalog"},
    {code: "ty", lang: "Tahitian"},
    {code: "tg", lang: "Tajik"},
    {code: "ta", lang: "Tamil"},
    {code: "tt", lang: "Tatar"},
    {code: "te", lang: "Telugu"},
    {code: "th", lang: "Thai"},
    {code: "bo", lang: "Tibetan"},
    {code: "ti", lang: "Tigrinya"},
    {code: "to", lang: "Tonga"},
    {code: "ts", lang: "Tsonga"},
    {code: "tr", lang: "Turkish"},
    {code: "tk", lang: "Turkmen"},
    {code: "tw", lang: "Twi"},
    {code: "ug", lang: "Uyghur"},
    {code: "uk", lang: "Ukrainian"},
    {code: "ur", lang: "Urdu"},
    {code: "uz", lang: "Uzbek"},
    {code: "ve", lang: "Venda"},
    {code: "vi", lang: "Vietnamese"},
    {code: "vo", lang: "Volapük"},
    {code: "wa", lang: "Wallon"},
    {code: "cy", lang: "Welsh"},
    {code: "wo", lang: "Wolof"},
    {code: "fy", lang: "Western Frisian"},
    {code: "xh", lang: "Xhosa"},
    {code: "yi,ji", lang: "Yiddish"},
    {code: "yo", lang: "Yoruba"},
    {code: "za", lang: "Zhuang, Chuang"},
    {code: "zu", lang: "Zulu",}
]