import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ButtonBase from "@material-ui/core/ButtonBase";
import Icon from "../Icon";
import {
    NAV_BAR_HEIGHT,
    NAV_BAR_BACKGROUND_COLOR,
    NAV_BAR_ICON_COLOR_SELECTED,
    NAV_BAR_ICON_COLOR_UNSELECTED,
    NAV_BAR_IOSX_EXTRA_PADDING,
    NAV_BAR_ICON_SIZE,
    NAV_BAR_TEXT_SIZE,
    FONT_WEIGHT_NORMAL,
} from "../../assets/styles";
import { isEmptyEvaluator } from "../../logic/Utilities";

const navbarButtonIndexMap = new Map();

class NavbarCore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
        }
    }

    componentDidMount() {
        this.fillNavbarButtonIndexMap();
        this.setNavbarnavbarIndex(this.props.history.location.pathname);
        this.intiUrlListiner();
    }

    componentWillUnmount() {
        this.unlisten();
    }

    intiUrlListiner() {
        this.unlisten = this.props.history.listen((history) => {
            this.setNavbarnavbarIndex(history.pathname)
        });
    }

    setNavbarnavbarIndex(pathFullName) {
        let pathNameArray = pathFullName.split("/");
        let pathName = pathNameArray[1];
        let selectedIndex = navbarButtonIndexMap.get(pathName);
        if (isEmptyEvaluator(selectedIndex)) {
            selectedIndex = 0;
        }
        this.setState({ selectedIndex: selectedIndex });
    }

    fillNavbarButtonIndexMap() {
        let navbarButtonList = this.props.navbarButtonList;
        for (let i = 0; i < navbarButtonList.length; i++) {
            navbarButtonIndexMap.set(navbarButtonList[i].path, i);
        }
    }

    changeScreen(path) {
        this.props.history.push("/" + path);
    }

    render() {
        let style = this.props.style;
        let navbarButtonList = this.props.navbarButtonList;
        let selectedIndex = this.state.selectedIndex;
        return (
            <div style={{ ...s.container, ...style }}>
                {navbarButtonList.map((info, index) => (
                    this.renderNavbarButton(info, selectedIndex, index)
                ))}
            </div>
        );
    }

    renderNavbarButton(info, selectedIndex, index) {
        let isSelected = selectedIndex === index;
        return (
            <ButtonBase
                key={index}
                style={{
                    ...s.navbarButton,
                    color: isSelected ? NAV_BAR_ICON_COLOR_SELECTED : NAV_BAR_ICON_COLOR_UNSELECTED,
                }}
                onClick={() => this.changeScreen(info.path)}
            >
                {this.renderNavbarButtonIcon(info.icon, isSelected)}
                <div style={s.navbarButtonLebel}>
                    {info.label}
                </div>
            </ButtonBase>
        );
    }

    renderNavbarButtonIcon(icon, isSelected) {
        if (isEmptyEvaluator(icon)) {
            return;
        }
        return (
            <Icon icon={icon} size={NAV_BAR_ICON_SIZE} color={isSelected ? NAV_BAR_ICON_COLOR_SELECTED : NAV_BAR_ICON_COLOR_UNSELECTED} />
        );
    }
}

export default withRouter(NavbarCore);

const s = {
    container: {
        zIndex: 4,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        backgroundColor: NAV_BAR_BACKGROUND_COLOR,
        paddingBottom: 0,
    },
    navbarButton: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 60,
        maxWidth: 170,
        height: NAV_BAR_HEIGHT,
    },
    navbarButtonLebel: {
        fontSize: NAV_BAR_TEXT_SIZE,
        fontWeight: FONT_WEIGHT_NORMAL,
        whiteSpace: "nowrap"
    }
};