import tommySamuelsson from "./photos/TommySamuelsson.jpg";
import williamLillieskold from "./photos/WilliamLillieskold.jpg";
//import oscarMolina from "./photos/OscarMolina.jpg";
import dagPalocci from "./photos/DagPalocci.jpeg";
import timMalmstrom from "./photos/TimMalmstrom.jpg";

export const employees = [
    {
        "name": "Tommy",
        "description": "Tommy är grundaren till ASD och arbetar som mjukvaruingenjör. Han älskar allt vad teknik heter och har en fantastisk känsla för helhet, design och kvalitet.",
        "photo": tommySamuelsson
    },
    {
        "name": "William",
        "description": "William är en mjukvaruutvecklare som trivs bäst när han får grotta ner sig i olika tekniker. Han tycker all form av problemlösning är roligt och älskar att lära sig nya saker.",
        "photo": williamLillieskold
    },
    {
        "name": "Oscar",
        "description": "Oscar är säljansvarig på ASD. Han är alltid glad och har en unik förmåga att sprida gläjde och engagemang till sin omgivning.",
        "photo": ""
    },
    {
        "name": "Dag",
        "description": "Dag är ASD:s ekonomichef. Han kan lite om mycket och tycker teknik och innovation är väldigt spännande.",
        "photo": dagPalocci
    },
    {
        "name": "Tim",
        "description": "Tim är teknisk rådgivare till ASD och har erfarenhet från både Google och Spotify. Han har ett lugnt yttre men ett knivskarpt intellekt och när han inte utvecklar så myser han med sina två kaniner, Rocket och Vicky.",
        "photo": timMalmstrom
    }
]