import { languageStrings } from "./languageStrings";
import { languageList } from "./LanguageList";
import { isEmptyEvaluator } from "../logic/Utilities";

const fallback = "en";
const detailedDeviceLanguage = getLanguage()[0].langCode;
let language;

if (isEmptyEvaluator(detailedDeviceLanguage)) {
    language = fallback;
}
else {
    language = "sv"//detailedDeviceLanguage.substring(0, 2);
}

export const deviceLanguage = language;

export function translate(line) {
    return languageStrings[language][line];
}

export function estimateLanguageCode(langCode) {
    if (langCode in languageStrings) {
        return langCode;
    }
    else {
        let languageCodeList = Object.keys(languageStrings);
        for (let i = 0; i < languageCodeList.length; i++) {
            if (langCode.includes(languageCodeList[i])) {
                return languageCodeList[i];
            }
        }
        return fallback;
    }
}

/**
 * @navigatorLang is an array of different language specifiers,
 * ex: ["en-GB", "en-US", "en"]
 */
function getLanguage() {
    let langArray = navigator.languages;
    let objectArray = [];
    for (let i = 0; i < langArray.length; i++) {
        objectArray.push({
            langCode: langArray[i],
            langName: getLangName(langArray[i])
        });
    }
    return objectArray
}

function getLangName(lcode) {
    for (let i = 0; i < languageList.length; i++) {
        if (languageList[i].code === lcode) {
            return languageList[i].lang;
        }
    }
    return "unknown";
}

export function translateFallback(line, fallbackLine) {
    if (isEmptyEvaluator(line)) {
        return translate(fallbackLine);
    }
    return line;
}