import React, { Component } from 'react';
import Icon from "./components/Icon";
import {
    BACKGROUND_COLOR,
    ICON_BODY_COLOR,
    FONT_HEADER_COLOR,
    FONT_HEADER_SIZE,
    FONT_WEIGHT_NORMAL,
} from "./assets/styles";
import { translate } from "./language/languageHandler";

export default class MissingPage extends Component {

    render() {
        let label = translate("missing_page");
        return (
            <div style={s.container}>
                <Icon icon={"block"} size={60} color={ICON_BODY_COLOR} />
                <div style={s.text}>
                    {label}
                </div>
            </div>
        );
    }

}

const s = {
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: BACKGROUND_COLOR,
    },
    text: {
        color: FONT_HEADER_COLOR,
        fontSize: FONT_HEADER_SIZE,
        fontWeight: FONT_WEIGHT_NORMAL,
    },
};