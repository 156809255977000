/**
 * Regex
 */
export const CORRECT_EMAIL = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
export const ONLY_DIGITS = "^[0-9]*$";
export const CORRECT_PASSWORD = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).{8,30}$"

export const LOCAL_VERSION = "1.0.7";
export const ASD_CONTACT_INFO_ENDPOINT = "https://anita-software-development.firebaseio.com/aboutASD.json";
export const ASD_LOGO_URL = "https://firebasestorage.googleapis.com/v0/b/anita-software-development.appspot.com/o/asd_logo.png?alt=media&token=c74cdce1-815b-4c48-80d7-1b331951823b"

/**
 * Google Analytics 
 */
 export const TRACKING_ID = "";

/**
 * Firebase
 */
export const CONFIG_FIREBASE = {
    apiKey: "AIzaSyCho5D3_3IByn_gWnnqv-5anUj160D4JeA",
    authDomain: "asd-audio-guide.firebaseapp.com",
    databaseURL: "https://asd-audio-guide.firebaseio.com",
    projectId: "asd-audio-guide",
    storageBucket: "asd-audio-guide.appspot.com",
    messagingSenderId: "1008270288718",
    appId: "1:1008270288718:web:09092c88dbab5e564de01f",
    measurementId: "G-4L4FNSLR2Z"
};