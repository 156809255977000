import React, { Component, } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
    BACKGROUND_COLOR,
    NAV_BAR_HEIGHT,
} from "../assets/styles";
import NavbarDesktop from "../components/Navbar/NavbarDesktop";
import Bottombar from "../components/Bottombar";

import Home from "./appContentPages/homeTab/Home";

import Project from "./appContentPages/projectTab/Project";

import About from "./appContentPages/aboutTab/About";

import PolicyAG from "./appContentPages/policyTab/PolicyAG";

import MissingPage from "../MissingPage";

class AppContentRoutes extends Component {

    render() {
        return (
            <BrowserRouter>
                <div style={s.container}>
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/projects" exact component={Project} />
                        <Route path="/about" exact component={About} />
                        <Route path="/privacy-policy-audio-guide" exact component={PolicyAG} />
                        <Route component={MissingPage} />
                    </Switch>
                </div>
                {this.renderBottombar()}
                {this.renderNavbar()}
            </BrowserRouter>
        );
    }

    renderBottombar() {
        return (
            <Bottombar/>
        );
    }

    renderNavbar() {
        return (
            <NavbarDesktop />
        );
    }

}

export default AppContentRoutes;

const s = {
    container: {
        display: "flex",
        minHeight: "calc(100vh - " + (NAV_BAR_HEIGHT) + "px)",
        marginTop: NAV_BAR_HEIGHT,
        backgroundColor: BACKGROUND_COLOR,
    },
};