import React, { Component } from "react";
import Image from "../components/Image";
import { isEmptyEvaluator } from "../logic/Utilities";
import OpacityOnClick from "./OpacityOnClick";
const START_SIZE = 140;

class AppIcon extends Component {

    render() {
        let style = this.props.style;
        let icon = this.props.icon;
        let size = this.props.size;
        let onClick = this.props.onClick;
        if (isEmptyEvaluator(size)) {
            size = START_SIZE;
        }
        return (
            <OpacityOnClick style={{ ...style, overflow: "hidden", borderRadius: size * 0.2 }} onClick={() => onClick()}>
                <Image
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size * 0.2,
                    }}
                    src={icon}
                    resizeMode={"contain"}
                />
            </OpacityOnClick>

        );
    }
}

export default AppIcon;