/**
 * Main color theme
 */
const PRIMARY_COLOR = "#02ACF2";
const SECONDARY_COLOR = "#ED90E4";
const TERTIARY_COLOR = "#F75E4B";
const SECONDARY_OF_COLOR = "#D4D4D4";
const PRIMARY_TEXT_COLOR = "#20213A";
const SECONDARY_TEXT_COLOR = "#FFFFFF";
const PRIMARY_BACKGROUND_COLOR = "#FFFFFF";
const PRIMARY_PANEL_BACKGROUND_COLOR = "#EDEDED";


/**
 * Header styles
 */
export const STATUS_BAR_HEIGHT_ANDROID = 24;
export const STATUS_BAR_HEIGHT_IOS_IPHONE_X = 40;
export const STATUS_BAR_HEIGHT_IOS = 20;
export const HEADER_HEIGHT_IOS_IPHONE_X = 88;
export const HEADER_HEIGHT_IOS = 64;
export const HEADER_HEIGHT_ANDROID = 56;
export const HEADER_TEXT_COLOR = PRIMARY_TEXT_COLOR;
export const HEADER_BACKGROUND_COLOR = PRIMARY_COLOR;
export const HEADER_SEARCH_FIELD_BACKGROUND_COLOR = PRIMARY_COLOR;

/**
 * SearchBar styles
 */
export const SEARCH_BAR_HEIGHT = 50;
export const SEARCH_BAR_FONT_COLOR = PRIMARY_TEXT_COLOR;
export const SEARCH_BAR_FONT_SIZE = 20;
export const SEARCH_BAR_ICON_COLOR = SECONDARY_OF_COLOR;
export const SEARCH_BAR_ICON_SIZE = 24;
export const SEARCH_BAR_BACKGROUND_COLOR = PRIMARY_PANEL_BACKGROUND_COLOR;
export const SEARCH_RESULT_BACKGROUND_COLOR = "black";

/**
 * Navigation styles
 */
export const NAV_BAR_ICON_SIZE = 24;
export const NAV_BAR_TEXT_SIZE = 12;
export const NAV_BAR_ICON_COLOR_SELECTED = SECONDARY_COLOR;
export const NAV_BAR_ICON_COLOR_UNSELECTED = SECONDARY_OF_COLOR;
export const NAV_BAR_TEXT_COLOR_SELECTED = SECONDARY_COLOR;
export const NAV_BAR_TEXT_COLOR_UNSELECTED = SECONDARY_OF_COLOR;
export const NAV_BAR_BACKGROUND_COLOR = PRIMARY_BACKGROUND_COLOR;
export const NAV_BAR_RIPPLE_EFECT_COLOR = SECONDARY_COLOR;
export const NAV_BAR_HEIGHT = 56;
export const NAV_BAR_IOSX_EXTRA_PADDING = 20;
export const NAV_BAR_TOP_BORDER_WIDTH = 1;
export const NAV_BAR_TOP_BORDER_COLOR = SECONDARY_OF_COLOR;
export const NAV_BAR_SAFE_MARGIN = NAV_BAR_HEIGHT + (false ? NAV_BAR_IOSX_EXTRA_PADDING : 0);

/**
 * Audio player styles
 */
export const PROGRESS_BAR_HEIGHT = 30;
export const AUDIO_PLAYER_HEIGHT = NAV_BAR_HEIGHT + PROGRESS_BAR_HEIGHT;

/**
 * Button styles
 */
export const BUTTON_DESKTOP_WIDTH = 300;
export const BUTTON_HEIGHT = 40;
export const BUTTON_BORDER_RADIUS = 8;
export const BUTTON_BACKGROUND_COLOR = SECONDARY_COLOR;
export const BUTTON_BACKGROUND_SELECTED_COLOR = SECONDARY_COLOR;
export const BUTTON_TEXT_COLOR = PRIMARY_TEXT_COLOR;

/**
 * Swistch styles
 */
export const SWITCH_COLOR = SECONDARY_COLOR;

/**
 * Checkbox styles
 */
export const CHECKBOX_COLOR = SECONDARY_COLOR;

/**
 * Radio Button styles
 */
export const RADIO_BUTTON_COLOR = SECONDARY_COLOR;
export const RADIO_BUTTON_SIZE = 24;

/**
 * Order card styles
 */
export const MY_ORDER_CARD_BACKGROUND_COLOR = PRIMARY_PANEL_BACKGROUND_COLOR;

/**
 * Card styles
 */
 export const CARD_MENU_BAR_COLOR = SECONDARY_COLOR;
 export const CARD_WIDTH = 700;
 export const CARD_HEIGHT = 200;
 export const CARD_TEXT_COLOR = "#F7F7F7";
 export const CARD_BACKGROUND_COLOR = PRIMARY_PANEL_BACKGROUND_COLOR;
 export const CARD_BOX_SHADOW = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";
 export const CARD_BOX_SHADOW_2 = "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"

/**
 * Back Button styles
 */
export const BACK_BUTTON_HEIGHT = 40;
export const BACK_BUTTON_WIDTH = 40;
export const BACK_BUTTON_BACKGROUND_COLOR = "#FFFFFF";
export const BACK_BUTTON_ICON_COLOR = "#000000";

/**
 * Attention Message styles
 */
export const ATTENTION_MESSAGE_BACKGRUND_ERROR_COLOR = "#FDECEA";
export const ATTENTION_MESSAGE_FONT_ERROR_COLOR = "#611A15";
export const ATTENTION_MESSAGE_ICON_ERROR_COLOR = "#F55448";

export const ATTENTION_MESSAGE_BACKGRUND_SUCCESS_COLOR = "#EDF7ED";
export const ATTENTION_MESSAGE_FONT_SUCCESS_COLOR = "#1E4620";
export const ATTENTION_MESSAGE_ICON_SUCCESS_COLOR = "#5cb660";

export const ATTENTION_MESSAGE_BACKGRUND_INFO_COLOR = "#E8F4FD";
export const ATTENTION_MESSAGE_FONT_INFO_COLOR = "#0D3C61";
export const ATTENTION_MESSAGE_ICON_INFO_COLOR = "#35A0F4";

export const ATTENTION_MESSAGE_BACKGRUND_WARNING_COLOR = "#FFF4E5";
export const ATTENTION_MESSAGE_FONT_WARNING_COLOR = "#663C00";
export const ATTENTION_MESSAGE_ICON_WARNING_COLOR = "#FFA117";


/** 
 * Text styles
 */
export const FONT_HEADER_COLOR = SECONDARY_TEXT_COLOR;
export const FONT_HEADER_SIZE = 50;
export const FONT_BODY_COLOR = PRIMARY_TEXT_COLOR;
export const FONT_BODY_COLOR_SELECTED = SECONDARY_COLOR;
export const FONT_BODY_COLOR_UNSELECTED = SECONDARY_OF_COLOR;
export const FONT_BODY_SIZE = 16;
export const FONT_DATE_SIZE = 12;
export const FONT_WEIGHT_NORMAL = "normal";
export const FONT_WEIGHT_BOLD = "bold";
export const FONT_FAMILY = "";
export const TEXT_SHADOW = "0.5px 0.5px 1px #000000";
export const CARET_COLOR = SECONDARY_COLOR;
export const UNDERLINE_COLOR = SECONDARY_COLOR;


//To be removed
export const TEXT_COLOR_1 = PRIMARY_TEXT_COLOR;
export const TEXT_COLOR_2 = SECONDARY_COLOR;
export const TEXT_COLOR_3 = PRIMARY_BACKGROUND_COLOR;
export const TEXT_COLOR_4 = SECONDARY_OF_COLOR;
export const TEXT_LINK_COLOR = SECONDARY_COLOR;
export const TEXT_SIZE_1 = 16;
export const TEXT_SIZE_2 = 20;
export const PLACE_HOLDER_TEXT_COLOR = SECONDARY_OF_COLOR;
export const PLACE_HOLDER_TEXT_SEARCH_COLOR = SECONDARY_OF_COLOR;

export const TEXT_BACKGRUND_COLOR = PRIMARY_COLOR;

/** 
 * CheckBox sytles
 */
export const CHECKBOX_BACKGRUND_COLOR = PRIMARY_BACKGROUND_COLOR;
export const CHECKBOX_BACKGRUND_SELECTED_COLOR = SECONDARY_COLOR;
export const CHECKBOX_CHECK_COLOR = "transparent";
export const CHECKBOX_CHECK_SELECTED_COLOR = PRIMARY_BACKGROUND_COLOR;
export const CHECKBOX_BORDER_COLOR = SECONDARY_COLOR;


/** 
 * Loading styles
 */
export const LOADING_CIRCLE_COLOR = SECONDARY_COLOR;
export const LOADING_CIRCLE_BACKGROUND_COLOR = PRIMARY_BACKGROUND_COLOR + "96";

/** 
 * Icon styles
 */
export const ICON_BACKGROUND_SIZE = 180;
export const ICON_BACKGROUND_COLOR = SECONDARY_OF_COLOR;
export const ICON_BODY_SIZE = 16;
export const ICON_BODY_COLOR = SECONDARY_OF_COLOR;
export const ICON_BULLET_POINT_SIZE = 24;
export const ICON_BULLET_POINT_COLOR = PRIMARY_TEXT_COLOR;
export const ICON_BULLET_POINT_COLOR_SELECTED = SECONDARY_COLOR;
export const ICON_CANCEL_SIZE = 24;
export const ICON_CANCEL_COLOR = SECONDARY_OF_COLOR;

export const ICON_PROFILE_COLOR = PRIMARY_TEXT_COLOR;
export const ICON_PROFILE_SIZE = 100;
export const ICON_PROFILE_SIZE_SMALL = 40;
export const ICON_CONTACT_US_COLOR = PRIMARY_TEXT_COLOR;
export const ICON_CONTACT_US_SIZE = 60;
export const ICON_LARGE_COLOR = PRIMARY_TEXT_COLOR;
export const ICON_LARGE_SIZE = 24;
export const ICON_SMALL_COLOR = SECONDARY_OF_COLOR;
export const ICON_SMALL_SIZE = 16;

/** 
 * Floating Action button styles
 */

export const FAB_BACKGROUND_COLOR = SECONDARY_COLOR;
export const FAB_ICON_COLOR = PRIMARY_TEXT_COLOR;
export const FAB_HEIGHT = 56;
export const FAB_ICON_SIZE = 24;

/** 
 * Meta styles
 */
export const SHADOW_COLOR = "#000000";
export const SHADOW_OFFSET_WIDTH = 1;
export const SHADOW_OFFSET_HEIGHT = 1;
export const SHADOW_OPACITY = 0.2;
export const ELEVATION = 2;
export const MARGIN_TOP = 18;
export const MARGIN_BOTTOM = 18;
export const MARGIN_LEFT = 18;
export const MARGIN_RIGHT = 18;
export const BORDER_RADIUS = 8;
export const BORDER_WIDTH = 1;
export const BORDER_COLOR_SELECTED = SECONDARY_COLOR;
export const BORDER_COLOR_UNSELECTED = SECONDARY_OF_COLOR;
export const BORDER_COLOR = PRIMARY_COLOR;
export const BACKGROUND_COLOR = PRIMARY_BACKGROUND_COLOR;
export const SELECTED_COLOR = SECONDARY_COLOR;
export const DESKTOP_WIDTH = 800;
export const IMAGE_PLACE_HOLDER_COLOR = "black";
export const BOX_SHADOW = "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)";
export const MAX_DESKTOP_WIDTH = 800;