import React, { Component } from "react";
import IconButtonMd from "@material-ui/core/IconButton";
import Icon from "./Icon";

class IconButton extends Component {

    render() {
        let icon = this.props.icon;
        let size = this.props.size;
        let color = this.props.color;
        let animationColor = this.props.animationColor;
        let onClick = this.props.onClick;
        let disabled = this.props.disabled;
        let opacity = 1;
        if (disabled) {
            opacity = 0.3;
        }
        return (
            <IconButtonMd style={{ color: animationColor, opacity: opacity }} onClick={() => onClick()} disabled={disabled}>
                <Icon icon={icon} size={size} color={color} />
            </IconButtonMd>
        );
    }
}

export default IconButton;