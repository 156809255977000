import React, { Component } from "react";
import { ASD_LOGO_URL } from "../assets/constants";
import Image from "../components/Image";
import { isEmptyEvaluator } from "../logic/Utilities";
import Text from "./Text.js";

class AsdLogo extends Component {

    render() {
        let style = this.props.style;
        let fallbackLogoSizie = this.props.fallbackLogoSizie;
        if (isEmptyEvaluator(ASD_LOGO_URL)) {
            return (
                <div style={s.logoContainer}>
                    <Text style={{ fontSize: fallbackLogoSizie }}>
                        {"<ANITA />"}
                    </Text>
                </div>
            );
        }
        else {
            return (
                <Image style={{ ...s.logoContainer, ...style }} src={ASD_LOGO_URL} resizeMode={"contain"} />
            );
        }
    }
}

export default AsdLogo;

const s = {
    logoContainer: {
        display: "flex",
        height: 140,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
    },
};