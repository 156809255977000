import React, { Component } from "react";
import MdDrawer from "@material-ui/core/Drawer";

class Drawer extends Component {

    render() {
        let anchor = this.props.anchor;
        let open = this.props.open;
        let onClose = this.props.onClose;
        let children = this.props.children;
        return (
            <MdDrawer
                anchor={anchor}
                open={open}
                onClose={() => onClose()}
            >
                {children}
            </MdDrawer>
        );
    }
}

export default Drawer;