import React, { Component } from "react";
import { Lens } from "@material-ui/icons";
import { iconLibraryDefault } from "../assets/IconLibraryDefault";

class Icon extends Component {

    getDefaultIcon(icon, style) {
        if (icon in iconLibraryDefault) {
            return iconLibraryDefault[icon](style);
        }
        else {
            return <Lens style={style} />;
        }
    }

    render() {
        let icon = this.props.icon;
        let size = this.props.size;
        let color = this.props.color;
        let style = { fontSize: size, color: color };
        return this.getDefaultIcon(icon, style);
    }
}

export default Icon;