import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
    NAV_BAR_HEIGHT,
    BACKGROUND_COLOR,
    FONT_HEADER_COLOR,
    FONT_HEADER_SIZE,
    FONT_WEIGHT_BOLD,
    MARGIN_TOP,
    CARD_BOX_SHADOW,
} from "../../../assets/styles";
import Text from "../../../components/Text";
import Image from "../../../components/Image";
import AppIcon from "../../../components/AppIcon";
import { translate } from "../../../language/languageHandler";
import psstLogo from "../../../assets/img/psst_icon.png";
import ffwIcon from "../../../assets/img/ffw_icon.png";
import avanzaIcon from "../../../assets/img/avanza_icon.png";
import nordiskaMuseetIcon from "../../../assets/img/nordiska_museet_icon.png";
import jamtliIcon from "../../../assets/img/jamtli_icon.png";
import asdPic from "../../../assets/img/asd_painting.jpeg";

import avanza_event from "../../../assets/img/avanza_event.png";
import avanza_home from "../../../assets/img/avanza_home.png";
import avanza_pay from "../../../assets/img/avanza_pay.png";
import ffw_desktop from "../../../assets/img/ffw_desktop.png";
import ffw_mobil from "../../../assets/img/ffw_mobil.png";
import key_hand from "../../../assets/img/key_hand.png";
import nordiska_home from "../../../assets/img/nordiska_home.png";
import nordiska_tracks from "../../../assets/img/nordiska_tracks.png";
import psst_mobil from "../../../assets/img/psst_mobil.png";
import psst_padda from "../../../assets/img/psst_padda.png";
const APP_ICON_SIZE = 100;

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            addLanguagePackPopUp: false,
        };
    }

    render() {
        return (
            <div style={s.container}>
                {this.renderHeader()}
                {this.renderPsst()}
                {this.renderFfw()}
                {this.renderAvanza()}
                {this.renderAudioguide()}
            </div>
        );
    }

    renderHeader() {
        return (
            <Image
                style={s.imageHeader}
                src={asdPic}
                resizeMode={"cover"}
            >
                <div
                    style={s.headerContainer}>
                    <div
                        style={s.headerTextContainer}>
                        <Text
                            style={s.textHeader}
                            selectable={true}>
                            {translate("home_projects")}
                        </Text>
                    </div>
                </div>
            </Image>
        );
    }

    renderPsst() {
        return (
            <div
                id={"projectPsst"}
                style={s.projectContainer}>
                <div
                    style={s.projectHeaderContainer}>
                    <AppIcon
                        style={s.appIcon}
                        icon={psstLogo}
                        size={APP_ICON_SIZE}
                        onClick={() => console.log("Project icon clicked. Remove click functionality.")}
                    />
                    <Text
                        style={s.textSubHeader}
                        selectable={true}>
                        {"Psst!"}
                    </Text>
                </div>
                <div
                    style={s.projectImagesContainer}>
                    <Image
                        style={s.projectImage}
                        src={psst_padda}
                        resizeMode={"contain"}
                    />
                    <Image
                        style={s.projectImage}
                        src={psst_mobil}
                        resizeMode={"contain"}
                    />
                </div>
            </div>
        )
    }

    renderFfw() {
        return (
            <div
                id={"projectFfw"}
                style={s.projectContainer}>
                <div
                    style={s.projectHeaderContainer}>
                    <AppIcon
                        style={s.appIcon}
                        icon={ffwIcon}
                        size={APP_ICON_SIZE}
                        onClick={() => console.log("Project icon clicked. Remove click functionality.")}
                    />
                    <Text
                        style={s.textSubHeader}
                        selectable={true}>
                        {"Faster Forward"}
                    </Text>
                </div>
                <div
                    style={s.projectImagesContainer}>
                    <Image
                        style={s.projectImage}
                        src={ffw_desktop}
                        resizeMode={"contain"}
                    />
                    <Image
                        style={s.projectImage}
                        src={ffw_mobil}
                        resizeMode={"contain"}
                    />
                </div>
            </div>
        )
    }

    renderAvanza() {
        return (
            <div
                id={"projectAvanza"}
                style={s.projectContainer}>
                <div
                    style={s.projectHeaderContainer}>
                    <AppIcon
                        style={s.appIcon}
                        icon={avanzaIcon}
                        size={APP_ICON_SIZE}
                        onClick={() => console.log("Project icon clicked. Remove click functionality.")}
                    />
                    <Text
                        style={s.textSubHeader}
                        selectable={true}>
                        {"Avanza"}
                    </Text>
                </div>
                <div
                    style={s.projectImagesContainer}>
                    <Image
                        style={s.projectImage}
                        src={avanza_home}
                        resizeMode={"contain"}
                    />
                    <Image
                        style={s.projectImage}
                        src={avanza_event}
                        resizeMode={"contain"}
                    />
                    <Image
                        style={s.projectImage}
                        src={avanza_pay}
                        resizeMode={"contain"}
                    />
                </div>
            </div>
        )
    }

    renderAudioguide() {
        return (
            <div
                id={"projectAudioGuide"}
                style={s.projectContainer}>
                <div
                    style={s.projectHeaderContainer}>
                    <AppIcon
                        style={s.appIcon}
                        icon={nordiskaMuseetIcon}
                        size={APP_ICON_SIZE}
                        onClick={() => console.log("Project icon clicked. Remove click functionality.")}
                    />
                    <Text
                        style={s.textSubHeader}
                        selectable={true}>
                        {"Audio Guide"}
                    </Text>
                </div>
                <div
                    style={s.projectImagesContainer}>
                    <Image
                        style={s.projectImage}
                        src={nordiska_home}
                        resizeMode={"contain"}
                    />
                    <Image
                        style={s.projectImage}
                        src={nordiska_tracks}
                        resizeMode={"contain"}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(About);

const s = {
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: BACKGROUND_COLOR,
        minHeight: "100%",
        width: "100%",
        overflow: "hidden",
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        width: "100%",
        background: "linear-gradient(to bottom right, #FFFFFF, #ED90E4, #ED90E4, #02ACF2)",
        backgroundBlendMode: "screen",
        opacity: "95%",
    },
    headerTextContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        width: "800px",
        maxWidth: "90vw",
    },
    projectContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "800px",
        maxWidth: "90vw",
        marginTop: "50px",
        //marginBottom: "50px",
    },
    projectHeaderContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "800px",
        maxWidth: "90vw",
        marginBottom: "50px",
    },
    projectImagesContainer: {
        height: "100%",
        width: "100%",
        display: "inline-block",
    },
    projectImage: {
        width: "400px",
        height: "400px",
        maxWidth: "90vw",
        maxHeight: "90vw",
        backgroundColor: "transparent",
        display: "inline-block",
        marginBottom: "50px",
    },
    textHeader: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        color: FONT_HEADER_COLOR,
        fontSize: FONT_HEADER_SIZE,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    textSubHeader: {
        display: "flex",
        flex: 1,
        fontSize: 34,
        fontWeight: FONT_WEIGHT_BOLD,
        padding: "5px",
        marginTop: "50px",
        marginBottom: "50px",
    },
    text: {
        display: "flex",
        flex: 1,
        fontSize: "calc(18px + .3vw)",
        padding: "5px",
    },
    appIcon: {
        marginRight: "30px",
        boxShadow: CARD_BOX_SHADOW,
    },
    imageHeader: {
        width: "100%",
        height: "calc(92vh - " + (NAV_BAR_HEIGHT) + "px)",
    },
};