import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
    NAV_BAR_HEIGHT,
    BACKGROUND_COLOR,
    FONT_HEADER_COLOR,
    FONT_HEADER_SIZE,
    FONT_WEIGHT_BOLD,
    CARD_BOX_SHADOW_2,
    BORDER_RADIUS,
} from "../../../assets/styles";
import Text from "../../../components/Text";
import Image from "../../../components/Image";
import Icon from "../../../components/Icon";
import { translate } from "../../../language/languageHandler";
import asdPic from "../../../assets/img/asd_painting.jpeg";
import { employees } from "../../../assets/employees/descriptions";
const APP_ICON_SIZE = 100;

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            addLanguagePackPopUp: false,
        };
    }

    render() {
        return (
            <div style={s.container}>
                {this.renderHeader()}
                {this.renderAboutCard()}
                {this.renderEmployeesContainer()}
            </div>
        );
    }

    renderHeader() {
        return (
            <Image
                style={s.imageHeader}
                src={asdPic}
                resizeMode={"cover"}
            >
                <div
                    style={s.headerContainer}>
                    <div
                        style={s.headerTextContainer}>
                        <Text
                            style={s.textHeader}
                            selectable={true}>
                            {translate("about_welcome")}
                        </Text>
                    </div>
                </div>
            </Image>
        );
    }

    renderAboutCard() {
        return (
            <div
                style={s.aboutContainer}>
                <Text style={s.text}
                    selectable={true}>
                    {translate("about_body")}
                </Text>
            </div>
        );
    }

    renderEmployeesContainer() {
        return (
            <div
                style={s.employeesContainer}>
                <Text
                    style={s.textSubHeader}
                    selectable={true}>
                    {translate("about_employees")}
                </Text>
                {this.renderEmployees()}
            </div>
        )
    }

    renderEmployees() {
        return (
            <div style={s.employees}>
                {employees.map((data, key) => this.renderEmployeeCard(data, key))}
            </div>
        );
    }

    renderEmployeeCard(data, key) {
        return (
            <div key={key} style={s.employeeCard}>
                <Image
                style={s.image}
                src={data.photo ? data.photo : "photo"}
                resizeMode={"cover"}
            >
                {this.renderPhoto(data.photo)}
            </Image>
                <div>
                    <Text
                        selectable={true}>
                        {data.description}
                    </Text>
                </div>
            </div>
        )
    }

    renderPhoto(photo) {
        if (!photo) {
            return <Icon icon="person" size="125" color="#000000" />
        }
    }
}

export default withRouter(About);

const s = {
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: BACKGROUND_COLOR,
        minHeight: "100%",
        width: "100%",
        overflow: "hidden",
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        width: "100%",
        background: "linear-gradient(to bottom right, #FFFFFF, #F75E4B, #F75E4B, #F75E4B)",
        backgroundBlendMode: "screen",
        opacity: "95%",
    },
    headerTextContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        width: "800px",
        maxWidth: "90vw",
    },
    aboutContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "800px",
        maxWidth: "90vw",
        marginTop: "50px",
        marginBottom: "50px",
    },
    textHeader: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        color: FONT_HEADER_COLOR,
        fontSize: FONT_HEADER_SIZE,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    textSubHeader: {
        display: "flex",
        flex: 1,
        fontSize: 34,
        fontWeight: FONT_WEIGHT_BOLD,
        padding: "5px",
        marginBottom: "50px",
    },
    text: {
        display: "flex",
        flex: 1,
        fontSize: "calc(18px + .3vw)",
        padding: "5px",
    },
    employeesContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        minHeight: 140,
        width: "800px",
        maxWidth: "90vw",
        marginBottom: "50px",
    },
    employees: {
        height: "100%",
        width: "100%",
    },
    employeeCard: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        width: "390px",
        maxWidth: "calc(90vw - 10px)",
        minHeight: "390px",
        margin: '5px',
        padding: "40px",
        float: "left",
        boxSizing: "border-box",
        boxShadow: CARD_BOX_SHADOW_2,
        borderRadius: BORDER_RADIUS,
    },
    imageHeader: {
        width: "100%",
        height: "calc(92vh - " + (NAV_BAR_HEIGHT) + "px)",
    },
    image: {
        width: "150px",
        height: "150px",
        border: "2px solid #F75E4B",
        borderRadius: "75px",
        margin: "20px",
        backgroundColor: "#FFFFFF",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    }
};